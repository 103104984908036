.tentang-kami .left-content {
  padding-right: 20px; /* Mengatur jarak antara konten kiri dan kanan */
  text-align: justify;
}

.tentang-kami .right-content {
  display: flex;
  text-align: left;
  justify-content: center; /* Mengatur posisi konten nilai perusahaan ke tengah */
}

.tentang-kami .right-content ul {
  list-style-type: none;
  padding: 0;
}

.tentang-kami .card ul li {
  /* Gaya tambahan untuk setiap nilai perusahaan */
  margin-bottom: 8px; /* Jarak antar nilai perusahaan */
}

.tentang-kami .card {
  border: none !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.tentang-kami .card .card-body {
  padding-left: 0;
}
