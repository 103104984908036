/* styles.css */
.service-section {
  text-align: center;
  /* background-color: #f5f5f5; */
}

.service-section h2 {
  color: #da1c36;
}

.service-section p {
  color: #666;
  margin-bottom: 40px;
}

.service-section h3 {
  margin-bottom: 10px;
  /* color: #da1c36; */
}

.service-section p:last-child {
  margin-bottom: 0;
}

.service-section .row {
  justify-content: center;
}

.service-section .col-md-6 {
  margin-bottom: 30px;
}

.service-section .col-md-6 h3 {
  font-size: 1.5rem;
}

.service-section .col-md-6 p {
  color: #555;
  text-align: left;
  line-height: 1.6;
}

.service-section p {
  font-size: 1rem;
}

.service-section .icon-container {
  background: #da1c36;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 30px;
}

.service-section .icon-container img {
  filter: invert(100%);
  width: 35px;
}
