/* styles.css */

/* Style for modal */
/* .modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  max-width: 90%;
} */

.modal-content {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
}

.modal-header {
  background-color: transparent;
  color: #1a1a1a;
  border-radius: 10px 10px 0 0;
  border: none;
}

.modal-title {
  font-weight: bold;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  border-radius: 0 0 10px 10px;
  border: none;
}

/* Style for form elements */
.form-control {
  border-radius: 5px;
  border-color: #ced4da;
}

.form-control:focus {
  border-color: #1a1a1a;
  box-shadow: none;
}

.form-label {
  font-weight: bold;
  font-size: 12px;
  color: #1a1a1a;
}

/* Style for buttons */
.btn {
  border-radius: 5px;
  font-weight: bold;
}

.btn-primary {
  background-color: #da1c36;
  border-color: #da1c36;
}

.btn-primary:hover {
  background-color: hsl(352, 86%, 51%);
  border-color: #ee1834;
}

.btn-secondary {
  background-color: transparent;
  border-color: transparent;
  color: #1a1a1a;
}

.btn-secondary:hover {
  background-color: transparent;
  border-color: #1a1a1a;
  color: #1a1a1a;
}

/* Style for dropdown */
.form-modal .dropdown {
  width: 100%;
}

/* Style for dropdown toggle */
.form-modal .dropdown-toggle {
  width: 100%;
  text-align: left; /* Mengatur teks menjadi rata kiri */
  background-color: white;
  color: black;
  border: 1px solid #ced4da; /* Sesuaikan sesuai kebutuhan Anda */
  font-weight: normal;
}

.dropdown-toggle::after {
  float: right; /* Menggeser ikon panah ke kanan */
  margin-top: 7px; /* Menyesuaikan vertikal dengan teks */
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

/* Style for dropdown menu */
.form-modal .dropdown-menu {
  width: 100%;
  background-color: white;
  color: #1a1a1a;
  border: 1px solid #ced4da; /* Ubah sesuai kebutuhan Anda */
  box-shadow: none;
}

/* Style for dropdown items */
.form-modal .dropdown-item {
  width: 100%;
  padding: 8px;
  white-space: nowrap;
  text-align: left;
}

/* Style for selected item */
.form-modal .dropdown-item.active {
  background-color: #fff; /* Ubah sesuai kebutuhan Anda */
  color: #1a1a1a;
}

.form-modal .dropdown-item.active,
.form-modal .dropdown-item:hover {
  background-color: white !important; /* Tetapkan latar belakang putih saat dihover */
  color: black !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
}

.alert {
  background: none;
  border: none;
  color: #ee1834;
  font-size: 12px;
  padding: 0;
  font-style: italic;
  margin-bottom: 0;
  font-weight: bold;
}

.alert::before {
  content: "* ";
  font-style: italic;
}
