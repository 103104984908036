body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfbff;
  font-family: "Arimo", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* styles.css */
.custom-navbar {
  background-color: #fff; /* Ubah sesuai warna yang diinginkan */
  box-shadow: none;
}

/* Mengatur warna teks menjadi putih */
.custom-navbar .navbar-brand,
.custom-navbar .navbar-nav .nav-link {
  color: #1b1b1b; /* Ubah sesuai warna teks yang diinginkan */
}

/* styles.css */
.minimal-card {
  border: none !important; /* Menghilangkan border */
  border-radius: 8px !important; /* Border radius */
  box-shadow: none; /* Hapus shadow */
  margin-bottom: 20px; /* Jarak bawah card */
}

.minimal-card .card-body {
  padding: 1rem; /* Padding body */
}

.minimal-card .card-title {
  font-size: 1.25rem; /* Ukuran font judul */
  margin-bottom: 0.5rem; /* Jarak antara judul dan harga */
}

.minimal-card .price {
  font-size: 1.5rem; /* Ukuran font harga */
  font-weight: bold; /* Bold teks harga */
  color: #333; /* Warna teks harga */
}

.minimal-card .card-text {
  font-size: 1rem; /* Ukuran font deskripsi */
  color: #555; /* Warna teks deskripsi */
}

.minimal-card .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 200px;
}

.minimal-card .img-container .card-img-top {
  width: 70%;
  object-fit: cover;
}

.padding-tb50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.w-80 {
  width: 80% !important;
  max-width: 80% !important;
}

.margin-b50 {
  margin-bottom: 50px;
}

.margin-b30 {
  margin-bottom: 30px;
}

.padding-0 {
  padding: 0 !important;
}

.padding-lr50 {
  padding: 0 50px !important;
}

.bold-text {
  font-weight: 700;
}

.text-align-justify {
  text-align: justify !important;
}

.ft-3rem {
  font-size: 3rem;
}

.third-color {
  color: #e1932e;
}

.secondary-color {
  color: #da1c36 !important;
}

.floating-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating-button a {
  display: block;
  width: 60px;
  height: 60px;
  background-color: #25d366; /* Warna hijau WhatsApp */
  border-radius: 50%; /* Untuk membuat lingkaran */
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.whatsapp-icon {
  width: 40px;
  height: 40px;
  color: #fff; /* Warna ikon */
  transition: color 0.3s; /* Transisi saat mengubah warna */
}

.floating-button a:hover {
  color: #25d366; /* Warna hijau WhatsApp saat hover */
  background-color: transparent !important;
  transition: color 0.3s, background-color 0.4s;
  /* border: 2px solid #25d366; */
}

.floating-button a:hover .whatsapp-icon {
  color: #25d366;
}

.bolder {
  font-weight: 900 !important;
}

.t-left {
  text-align: left !important;
}

.divider {
  border: 2px solid #da1c36;
  width: 40px;
  display: block;
  margin: 10px 0;
  border-radius: 2px;
}

.margin-auto {
  margin: auto !important;
}

.center-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button-ig {
  background: transparent;
  transition: background-image 6s ease;
}

.button-ig:hover {
  /* border-bottom: 3px solid #da1c36; */
  background: linear-gradient(
    to top,
    rgba(218, 28, 54, 0.7),
    rgba(218, 28, 54, 0),
    rgba(218, 28, 54, 0),
    rgba(218, 28, 54, 0)
  );
  background: linear-gradient(
    to top,
    rgba(218, 28, 54, 0.7),
    rgba(218, 28, 54, 0),
    rgba(218, 28, 54, 0),
    rgba(218, 28, 54, 0)
  );
  transition: background 3s ease;
}
