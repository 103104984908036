/* styles.css */
.header-section {
  position: relative;
  padding: 100px 0;
  background-color: #f8f9fa;
  text-align: center;
  font-family: "Arimo", sans-serif;
  background-image: url(../images/banner-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.header-section .container {
  z-index: 1;
  position: inherit;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Warna hitam dengan transparansi */
  /* z-index: -1; Diletakkan di belakang kontainer */
}

.hc-container {
  display: flex;
  justify-content: center; /* Menyatukan ke tengah secara horizontal */
  align-items: center; /* Menyatukan ke tengah secara vertikal */
}

.hc-container .header-content {
  text-align: left;
}

.header-content h1 {
  font-size: 5rem;
  margin-bottom: 20px;
  color: #f8f9fa;
  font-weight: 100;
}

.header-content p {
  color: #f8f9fa;
  margin-bottom: 30px;
  line-height: 1.6;
  font-weight: 400;
}

.header-image img {
  width: 100%;
  /* max-width: 400px; */
  display: block;
  margin: 0 auto;
  border-radius: 10px;
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); */
}

/* styles.css */
.btn-whatsapp {
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 6px;
  border: 1px solid #da1c36;
  color: #f8f9fa; /* Warna teks sesuai border */
  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
  background-color: #da1c36;
}

.btn-whatsapp:hover {
  color: #da1c36;
  border: 1px solid #da1c36; /* Warna border saat hover */
  background-color: #f8f9fa;
  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
}

.header-section .booking-button {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .hc-container {
    margin-bottom: 30px;
  }

  .header-content h1 {
    font-size: 2.5rem;
  }

  .header-section .booking-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #1a1a1a !important;
    font-size: 16px;
    color: #f8f9fa !important;
    border: 1px solid #da1c36;
  }
}
