/* styles.css */
/* ... */
.footer {
  background-color: #1a1a1a;
  padding: 40px 0;
  color: #fbfbff;
}

.footer h5 {
  text-align: left;
}

.footer hr {
  border-top: 1px solid #ccc;
}

.footer p {
  margin-bottom: 5px;
  text-align: left;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer ul li {
  margin-bottom: 5px;
  text-align: left;
}

.footer ul li a {
  text-decoration: none;
  color: #fbfbff;
}

.footer ul li a:hover {
  font-weight: bold;
}

.footer .mapouter {
  position: relative;
  text-align: right;
  height: 150px;
  width: 100%;
}

.footer .gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 150px;
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .footer .container .row .col-12 {
    margin-bottom: 30px;
  }
}
