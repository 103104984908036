/* styles.css */
.contact-section {
  padding: 50px 0;
  text-align: center;
  /* background-color: #f8f9fa; */
}

.contact-section h2 {
  margin-bottom: 20px;
  color: #da1c36;
}

.contact-section p {
  color: #666;
  margin-bottom: 30px;
  line-height: 1.6;
}

.contact-section .card {
  background-color: #fff;
  border: none;
  border-radius: 8px;
  margin-bottom: 20px;
}

.contact-section .card-title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.contact-section .list-group-item {
  border: none;
  padding: 8px 0;
}

.contact-section .list-group-item a {
  color: #333;
  text-decoration: none;
}

.contact-section .list-group-item a:hover {
  text-decoration: none;
  font-weight: bold;
  transition: font-weight 0.1s ease-in-out;
}

.contact-section iframe {
  border-radius: 8px; /* Sesuaikan ukuran border-radius sesuai preferensi Anda */
  overflow: hidden; /* Ini akan memastikan konten di dalam iframe tetap terpotong pada sudut terbulat */
}
