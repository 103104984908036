/* CSS untuk floating navigation */
.navigation .navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff; /* Ubah sesuai preferensi Anda */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: transparent; /* Ubah ke transparan agar efek scroll terlihat */
  transition: background-color 0.5s, box-shadow 0.5s; /* Transisi efek scroll */
}

.navigation .navbar.scrolling-active {
  background-color: #f8f9fa; /* Warna latar belakang saat di-scroll */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Tambahkan shadow saat di-scroll */
}

.navigation .nav-left a {
  text-decoration: none;
  color: #f8f9fa; /* Ubah sesuai preferensi Anda */
}

.navigation .nav-right a {
  text-decoration: none;
  color: #f8f9fa; /* Ubah sesuai preferensi Anda */
  margin-left: 20px;
}

/* CSS untuk section */
.navigation section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  scroll-margin-top: 60px; /* Untuk menyesuaikan navigasi floating */
}

.navigation .navbar-scroll {
  background-color: transparent;
  transition: background-color 0.5s, box-shadow 0.5s;
}

.navigation .navbar-scroll.scrolling-active {
  background-color: #f8f9fa;
  color: #1a1a1a;
  color: #da1c36;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navigation .nav-link,
.navigation .navbar-brand {
  color: #f8f9fa;
}

.navigation .navbar-scroll.scrolling-active .nav-link {
  color: #1a1a1a;
}

.navigation .navbar-scroll.scrolling-active .navbar-brand {
  color: #da1c36;
}

.navigation .navbar-scroll.scrolling-active .nav-link.active {
  color: #da1c36;
  font-weight: bold;
}

.navigation .navbar-scroll .nav-link.active {
  color: #da1c36;
  font-weight: bold;
}

.booking-button {
  background-image: linear-gradient(
    to bottom,
    #f61b38,
    #da1c36
  ); /* Gradasi dua warna merah dari atas ke bawah */
  color: #f8f9fa;
  margin-left: 10px; /* Jarak antara tombol Booking dan navigasi lainnya */
  font-weight: bold;
}

.booking-button:hover {
  color: #f8f9fa;
  background-image: linear-gradient(to bottom, #da1c36, #f61b38);
  font-weight: bold;
}

.navbar-scroll {
  /* Mengatur tinggi navbar agar tombol Booking tetap selaras dengan bagian lain */
  height: 60px;
}

.navbar-scroll .navbar-nav .nav-link {
  line-height: 60px; /* Menyesuaikan ketinggian link navigasi agar sejajar dengan tombol Booking */
}

.navigation .navbar-brand {
  font-weight: 700;
  /* color: #da1c36 !important; */
}

.navigation .navbar-brand .brand-logo {
  margin-right: 10px;
  filter: brightness(0) invert(1);
}

.navigation .navbar-scroll.scrolling-active .navbar-brand .brand-logo {
  filter: none;
}

.navbar-collapse {
  z-index: 999 !important;
}

@media only screen and (max-width: 1024px) {
  .navigation .navbar-toggler,
  .navbar-toggler:focus {
    border: none;
    box-shadow: none;
  }

  .navigation .navbar-toggler-icon {
    filter: brightness(0) invert(1);
  }

  .navigation .navbar-collapse {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    padding: 10px;
    z-index: -1;
    border-radius: 10px;
    color: #1a1a1a;
    margin-top: 15px;
    transition: background-color 0.5s, backdrop-filter 0.5s;
  }

  .navigation .navbar-scroll.scrolling-active .navbar-collapse {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    transition: background-color 0.5s, backdrop-filter 0.5s;
  }

  .navigation .navbar-scroll.scrolling-active .navbar-toggler-icon {
    filter: none;
  }

  .navigation .booking-button {
    display: none;
  }
}
