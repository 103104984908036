.etalase .card .card-body .card-text.desc {
  text-align: left;
}

.etalase {
  background-color: #1a1a1a;
  color: #fbfbff;
}

.etalase .card {
  /* background-color: rgba(218, 28, 53, 0.4); */
  background-color: transparent;
  background-image: url("../images/bg.svg");
  background-repeat: no-repeat;
  color: #fbfbff;
  position: relative;
}

.etalase .card .price {
  color: #fbfbff;
}

.etalase .card .desc {
  color: #fbfbff;
}

.etalase .img-container {
  position: relative;
}

/*
.etalase .top .card-title::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-bottom: 3px solid #da1c36;
  width: 50px;
} */

.etalase .top {
  height: 80px;
  text-align: left;
  position: relative;
  margin-bottom: 10px;
}

.etalase .bottom {
  height: 100px;
  text-align: left;
  position: relative;
}

.etalase .bottom p {
  position: relative;
  padding-left: 10px;
}

.etalase .bottom p::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 4px solid #da1c36;
  height: 100%;
}
