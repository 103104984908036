/* styles.css */
.testimonial-section {
  text-align: center;
  /* background-color: #da1c36; */
  background-image: url(../images/testi-bg.jpg);
  position: relative;
  background-size: cover; /* Menyesuaikan ukuran gambar */
  background-position: center;
}

.testimonial-section .container {
  z-index: 1;
  position: inherit;
}

.testimonial-section .overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

.testimonial-section h2 {
  color: #f8f9fa;
}

.testimonial-section p {
  color: #f8f9fa;
}

.testimonial-section .card {
  border: none;
  background-color: #f8f9fa;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.testimonial-section .card-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.testimonial-section .testimonial-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: center;
}

.testimonial-section .testimonial-name {
  font-weight: bold;
  margin-bottom: 0;
  text-align: left;
  color: #1a1a1a;
}

.testimonial-section .testimonial-rating {
  display: flex;
  align-items: center;
}

.testimonial-section .testimonial-rating svg {
  margin-right: 3px;
}

.testimonial-section .testimonial-bottom {
  flex: 1;
}

.testimonial-section .testimonial-text {
  color: #555;
  text-align: left;
}

.img-slider {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.img-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
}

.testimonial-section .react-slideshow-wrapper .images-wrap.horizontal {
  gap: 10px;
}

.testimonial-section .react-slideshow-container {
  margin-top: 50px;
}

.testimonial-section .react-slideshow-container .default-nav {
  background-color: rgba(255, 255, 255, 0.4);
  height: 100%;
}

.testimonial-section .react-slideshow-container .default-nav:first-of-type {
  border-radius: 0 50% 50% 0;
}

.testimonial-section .react-slideshow-container .default-nav:last-of-type {
  border-radius: 50% 0 0 50%;
}

.testimonial-section .react-slideshow-container .default-nav svg {
  fill: #da1c36;
  /* padding: 5px; */
  /* background: #da1c36; */
}

@media (max-width: 767px) {
  .testimonial-section .card-container {
    margin-bottom: 30px;
  }
}
